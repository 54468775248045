<template>
  <div class="mb-6 flex items-end justify-between">
    <div class="flex items-center">
      <span><InputForm @change="fetchCommissions(1)" v-model="filter.start_date" input_class="w-40 me-2" type="date"/></span>
      <MinusIcon class="w-5 h-5"/>
      <span><InputForm @change="fetchCommissions(1)" v-model="filter.end_date" input_class="w-40 ms-2" type="date"/></span> 
      <span class="ms-3">
        <select v-model="selectedEmployee" @change="fetchCommissions(1)" class="block w-48 text-lg mt-1 flex-1 border border-gray-400 rounded-lg px-3 py-2 text-gray-500 placeholder:text-gray-300 placeholder:text-[12px] sm:text-sm sm:leading-6 focus:outline-none focus:ring focus:ring-gray-500">
          <option value="" selected>Semua staff</option>
          <option v-for="employee in filteredEmployee" :key="employee.id" :value="employee.id">{{ employee.name }}</option>
        </select>
      </span>
    </div>
    <div v-if="this.$store.state.detail_user.user_login.is_super" class="flex">
      <!-- <button 
        @click="filterByOutlet(null)"
        :class="['px-3 me-2 text-sm rounded-lg border border-gray-500', selectedOutlet === null ? 'bg-gray-500 text-white font-bold' : '']">
        ALL
      </button>
      <button
        v-for="outlet in allOutlets"
        :key="outlet.id"
        @click="filterByOutlet(outlet.id)"
        :class="['px-3 me-2 py-1 text-sm rounded-lg border border-gray-500 uppercase', selectedOutlet === outlet.id ? 'bg-gray-500 text-white font-bold' : '']">
        {{ outlet.name }}
      </button> -->
    </div>
  </div>
  <div class="overflow-x-scroll">
    <table class="table-auto w-full mb-6">
      <thead class="bg-gray-300">
        <tr>
          <th class="p-3 border-x text-center">No</th>
          <th class="p-3 border-x text-center">ID Transaksi</th>
          <th class="p-3 border-x text-left">Transaksi Selesai</th>
          <th class="p-3 border-x text-left">Staff</th>
          <th class="p-3 border-x text-left">Nama Produk</th>
          <th class="p-3 border-x text-left">Total Harga Produk</th>
          <th class="p-3 border-x text-left flex justify-between items-center">Komisi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="processedCommissions.length <= 0">
          <td colspan="7" class="p-3 text-center italic text-gray-400">{{ 'Tidak ada data' }}</td>
        </tr>
        <tr v-for="(commission, index) in processedCommissions" :key="commission.id" class="border-y">
          <td class="p-3 text-center">{{ index + 1 }}</td>
          <td class="p-3 text-center">{{ commission.transactionCode || 'Loading...' }}</td>
          <td class="p-3">{{ formatTime(commission?.createdAt) }}</td>
          <td class="p-3 capitalize">{{ commission?.user?.name }}</td>
          <td class="p-3">{{ commission.productNames || 'Loading...' }}</td>
          <td class="p-3">{{ formatRupiah(commission.pos_transaction?.grand_total) }}</td>
          <td class="p-3">{{ formatRupiah(commission.commission) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
 </template>
 
<script>
import InputForm from '@/components/form/InputForm.vue';
import { mapGetters, mapActions } from 'vuex';
import { formatTime, formatRupiah } from '@/helpers/formating';
import { MinusIcon } from '@heroicons/vue/24/solid';

  export default {
    name: "ReportCommission",
    components: {
      InputForm,
      MinusIcon
    },
    data() {
      return {
        selectedEmployee: '',
        selectedOutlet: null,

        filter: {
          start_date: new Date().toISOString().split('T')[0],
          end_date: new Date().toISOString().split('T')[0]
        },

        processedCommissions: [],
      }
    },
    computed: {
      ...mapGetters('commissions', ['allCommissions', 'pagination']),
      ...mapGetters('employees', ['allEmployees']),
      // ...mapGetters('outlets', ['allOutlets']),

      userLogin() {
        const user = this.$store.state.detail_user.detail_user;
        return user;
      },
      filteredEmployee() {
        let employees = this.allEmployees.filter(employee => employee.role_id == 2);

        if(this.selectedOutlet) {
          employees = employees.filter(employee => employee.outlet_id == this.selectedOutlet);
        }

        if (!this.$store.state.detail_user.user_login?.is_super && this.userLogin.outlet_id) {
          employees = employees.filter(employee => employee.outlet_id == this.userLogin.outlet_id);
        }

        return employees;
      },

      filteredCommissions: {
        get() {
          return this.processedCommissions;
        },
        set(commissions) {
          let filteredCommissions = commissions;

          if(this.selectedOutlet) {
            filteredCommissions = filteredCommissions.filter(commission => commission.pos_transaction.outlet_id == this.selectedOutlet);
          }

          if (!this.$store.state.detail_user.user_login?.is_super && this.userLogin.outlet_id) {
            filteredCommissions = filteredCommissions.filter(commission => commission.pos_transaction.outlet_id == this.userLogin.outlet_id);
          }

          // DIUBAH: Proses data secara asynchronous
          this.processCommissions(filteredCommissions);
        }
      }
    },
    created() {
      this.fetchCommissions(this.pagination.page || 1);
      this.$store.dispatch('employees/fetchAllEmployees');
    },
    methods: {
      formatRupiah, formatTime,
      ...mapActions('commissions', ['fetchCommissions']),
      ...mapActions('transactions', ['fetchTransactionById']),
      ...mapGetters('outlets', ['allOutlets']),

      filterByOutlet(outletId) {
        this.selectedOutlet = outletId;
        this.fetchCommissions(1);
      },
  
      async fetchCommissions(page = 1, limit = 15, type = "start_date") {
        // Check if start_date is greater than end_date
        if (type == "start_date" && this.filter.start_date > this.filter.end_date) {
          this.filter.end_date = this.filter.start_date; // Set end_date to start_date
        }

        // Check if end_date is less than start_date
        if (type == "end_date" && this.filter.start_date > this.filter.end_date) {
          this.filter.start_date = this.filter.end_date; // Set start_date to end_date
        }
          
        const params = {
          page,
          limit,
          user_id: this.selectedEmployee,
          start_date: this.filter.start_date,
          end_date: this.filter.end_date
        };
        await this.$store.dispatch('commissions/fetchCommissions', params);
        this.filteredCommissions = this.allCommissions;
      },

      async processCommissions(commissions) {
        const processedCommissions = await Promise.all(commissions.map(async (commission) => {
          const transaction = await this.fetchTransactionById(commission.pos_transaction_id);
          // console.log('trx by id: ', transaction.data);
          
          commission.transactionCode = transaction.data?.transaction_code || '~';
          
          const productNames = transaction.data?.service_items?.map(item => item.product_detail.name) || [];
          commission.productNames = productNames.join(', ');
          
          return commission;
        }));

        this.processedCommissions = processedCommissions;
      }
    }
  }
</script>