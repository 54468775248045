<template>
  <div class="mb-6 flex items-center">
    <span><InputForm @change="fetchReportCommissions()" v-model="filter.startDate" input_class="w-40 me-2" type="date"/></span>
    <MinusIcon class="w-5 h-5"/>
    <span><InputForm @change="fetchReportCommissions()" v-model="filter.endDate" input_class="w-40 ms-2" type="date"/></span> 
  </div>
  <table class="table-auto w-full mb-6">
    <thead class="bg-gray-300">
      <tr>
        <th class="p-3 border text-center">No</th>
        <th class="p-3 border text-left">Staff</th>
        <th class="p-3 border text-left">Total Komisi</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="allReportCommission.length == 0">
        <td colspan="3" class="text-center py-3 italic text-gray-400">Tidak ada data</td>
      </tr>
      <tr v-for="(item, index) in allReportCommission" :key="item.id">
        <td class="p-3 border-y text-center">{{ index + 1 }}</td>
        <td class="p-3 border-y text-left">{{ item.user?.name }}</td>
        <td class="p-3 border-y text-left">{{ formatRupiah(item.total_commission) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import InputForm from '@/components/form/InputForm.vue';
import { mapGetters, mapActions } from 'vuex';
import { formatRupiah } from '@/helpers/formating';
import { MinusIcon } from '@heroicons/vue/24/solid';

export default {
  name: "ReportCommission",
  components: {
    InputForm,
    MinusIcon
  },
  data() {
    return {
      filter: {
        startDate: new Date().toISOString().split('T')[0],
        endDate: new Date().toISOString().split('T')[0]
      }
    }
  },
  computed: {
    ...mapGetters('report_commissions', ['allReportCommission']),
  },
  created() {
    this.fetchReportCommissions();
  },
  methods: {
    formatRupiah,
    ...mapActions('report_commissions', ['fetchReportCommissions']),

    async fetchReportCommissions(startDate = this.filter.startDate, endDate = this.filter.endDate, type = "start_date") {
      // Check if start_date is greater than end_date
      if (type == "start_date" && this.filter.startDate > this.filter.endDate) {
        this.filter.endDate = this.filter.startDate; // Set end_date to start_date
      }

      // Check if end_date is less than start_date
      if (type == "end_date" && this.filter.startDate > this.filter.endDate) {
        this.filter.startDate = this.filter.endDate; // Set start_date to end_date
      }

      const params = {
        startDate,
        endDate
      }
      await this.$store.dispatch('report_commissions/fetchReportCommissions', params);
    }
  }
}
</script>