<template>
  <div class="mb-6 flex items-center">
    <span><InputForm @change="fetchReportPaymentMethods()" v-model="filter.start_date" input_class="w-40 me-2" type="date"/></span>
    <MinusIcon class="w-5 h-5"/>
    <span><InputForm @change="fetchReportPaymentMethods()" v-model="filter.end_date" input_class="w-40 ms-2" type="date"/></span> 
  </div>
  <table class="table-auto w-full mb-6">
    <thead class="bg-gray-300">
      <tr>
        <th class="p-3 border text-center">No</th>
        <th class="p-3 border text-left">Metode Pembayaran</th>
        <th class="p-3 border text-left">Total Penggunaan</th>
        <th class="p-3 border text-left">Total Pembayaran</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="allReportPaymentMethod.length == 0">
        <td colspan="4" class="text-center py-3 italic text-gray-400">Tidak ada data</td>
      </tr>
      <tr v-for="(item, index) in allReportPaymentMethod" :key="item.id">
        <td class="p-3 border-y text-center">{{ index + 1 }}</td>
        <td class="p-3 border-y text-left">{{ item.payment_method?.description }}</td>
        <td class="p-3 border-y text-left">{{ item.total_usage }}</td>
        <td class="p-3 border-y text-left">{{ formatRupiah(item.total_payment) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import InputForm from '@/components/form/InputForm.vue';
import { mapGetters, mapActions } from 'vuex';
import { formatRupiah } from '@/helpers/formating';
import { MinusIcon } from '@heroicons/vue/24/solid';

export default {
  name: "ReportPaymentMethod",
  components: {
    InputForm,
    MinusIcon
  },
  data() {
    return {
      filter: {
        start_date: new Date().toISOString().split('T')[0],
        end_date: new Date().toISOString().split('T')[0]
      }
    }
  },
  computed: {
    ...mapGetters('report_payment_methods', ['allReportPaymentMethod']),
  },
  created() {
    this.fetchReportPaymentMethods();
  },
  methods: {
    formatRupiah,
    ...mapActions('report_payment_methods', ['fetchReportPaymentMethods']),

    async fetchReportPaymentMethods(startDate = this.filter.start_date, endDate = this.filter.end_date, type = "start_date") {
       // Check if start_date is greater than end_date
       if (type == "start_date" && this.filter.start_date > this.filter.end_date) {
          this.filter.end_date = this.filter.start_date; // Set end_date to start_date
        }

        // Check if end_date is less than start_date
        if (type == "end_date" && this.filter.start_date > this.filter.end_date) {
          this.filter.start_date = this.filter.end_date; // Set start_date to end_date
        }

      const params = {
        startDate,
        endDate
      }
      await this.$store.dispatch('report_payment_methods/fetchReportPaymentMethods', params);
    }
  }
}
</script>